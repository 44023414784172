import is from 'is_js'
import { mapGetters } from 'vuex'
import Papa from 'papaparse'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods: {
    capitalizeFirstLetter(str) {
      str.charAt(0).toUpperCase() + str.slice(1)
    },
    ucwords(str) {
      if (typeof str !== 'undefined') {
        return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase()
        })
      } else {
        return str
      }
    },
    abbrev(value) {
      let newValue = value
      if (value >= 1000) {
        let suffixes = ['', 'k', 'm', 'b', 't']
        let suffixNum = Math.floor(('' + value).length / 3)
        let shortValue = ''
        for (let precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision))
          let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
          if (dotLessShortValue.length <= 2) {
            break
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
        newValue = shortValue + suffixes[suffixNum]
      }
      return newValue
    },
    cnumbers(val) {
      val = '' + val
      if (is.existy(val) && is.not.empty(val)) {
        let isNeg = val.indexOf('-') > -1
        let number = +val.replace(/[^\d.]/g, '')
        number = isNaN(number) ? 0 : number
        let i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(0))))
        let j = i.length
        j = j > 3 ? j % 3 : 0
        return (isNeg ? '-' : '') + (j ? i.substr(0, j) + ',' : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1,')
      }
      return val
    },

    downloadCsv(jsonData) {
      let blob = new Blob([Papa.unparse(jsonData)], { type: 'text/csv;charset=utf-8;' })
      let link = document.createElement('a')
      let url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('downloas', 'filename.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    makeToast(variant = null, title, message, loc = 'b-toaster-top-right') {
      this.$bvToast.toast(message, {
        title: title,
        toaster: loc,
        variant: variant,
        solid: true
      })
    },
    showSuccess(title, message) {
      this.makeToast('success', title, message)
    },
    showError(title, message) {
      this.makeToast('danger', title, message, 'b-toaster-top-center')
    },
    showTokenExpired() {
      this.$bvModal
        .msgBoxOk('Your login session expired. Please login in again.', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(() => {
          this.$store.dispatch('logout').then(() => {
            this.$router.push('/auth')
          })
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },

    handleApiError(resp) {
      this.syslog(resp)
      let k = 'Error occurred. Contact System administrator.'
      let m = is.existy(resp) && is.existy(resp.message) ? resp.message : k
      m = this.getParamError(resp, m)
      if (m === 'Unauthenticated.') {
        if (this.isLoggedIn) {
          this.showTokenExpired()
        } else {
          this.$store.dispatch('logout').then(() => {
            this.$router.push('/auth')
          })
        }
      } else {
        if (m.includes('SQLSTATE')) {
          m = 'An SQL error occured. Contact System administrator.'
        }
        this.showError('Error occured', m)
      }
    },
    handleFormError(errors, myerrors) {
      if (is.existy(errors)) {
        for (let field in myerrors) {
          if (errors.hasOwnProperty(field)) {
            myerrors[field].status = false
            myerrors[field].message = errors[field][0]
          }
        }
      }
      return myerrors
    },
    getParamError(resp, m) {
      if (is.existy(resp) && is.existy(resp.errors)) {
        let errors = resp.errors
        let msg = ''
        for (let x in errors) {
          msg += errors[x][0] + '\r\n'
        }
        m = msg
      }
      return m
    },
    syslog(item) {
      // eslint-disable-next-line no-console
      console.log(item)
    }
  }
}
