import service from '../../api/firm'
import helpers from '../../mixins/index'

// Mutation types
const SET_FIRMS = 'SET_FIRMS'

const state = {
  firms: []
}

// getters
const getters = {
  getFirms: state => state.firms,
  getFirmsDropdown: state => {
    let x = state.firms.map(function (e) {
      return { id: e.id, label: e.name }
    }).sort(function (a, b) {
      return a.label.localeCompare(b.label)
    })
    x.unshift({id: 0, label: 'All'})
    return x
  },
  getFirmsDropdown2: state => {
    let x = state.firms.filter(function (e) {
      return e.id !== 1
    }).map(function (e) {
      return { id: e.id, label: e.name }
    }).sort(function (a, b) {
      return a.label.localeCompare(b.label)
    })
    x.unshift({id: 0, label: 'All'})
    return x
  }
}

// mutations
const mutations = {
  [SET_FIRMS] (state, payload) {
    state.firms = payload
  }
}

const actions = {
  getFirms ({commit: commit}, params) {
    return service.getFirms(params)
      .then((response) => {
        commit(SET_FIRMS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
