import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'userService',
  components: {
    apiService
  },

  getUsers(params = {}) {
    return apiService
      .get(EndPoints.USERS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getUser(id) {
    let url = EndPoints.USERS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getRoles() {
    let url = EndPoints.ROLES_URL

    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addUser(user) {
    let url = EndPoints.USERS_URL

    return apiService
      .create(url, user)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateUser(user) {
    let url = EndPoints.USERS_URL + '/' + user.userID

    return apiService
      .update(url, user)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeUser(user) {
    let url = EndPoints.USERS_URL + '/' + user.userID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getRecruits(params = {}) {
    return apiService
      .get(EndPoints.RECRUITS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getRecruit(id) {
    let url = EndPoints.RECRUITS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addRecruit(recruit) {
    let url = EndPoints.RECRUITS_URL

    return apiService
      .create(url, recruit)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  graduateRecruit(details) {
    let url = EndPoints.RECRUITS_URL + '/graduate'

    return apiService
      .create(url, details)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },

  updateRecruit(recruit) {
    let url = EndPoints.RECRUITS_URL + '/' + recruit.id

    return apiService
      .update(url, recruit)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeRecruit(recruit) {
    let url = EndPoints.RECRUITS_URL + '/' + recruit.id

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getEnumerators(params = {}) {
    return apiService
      .get(EndPoints.ENUMERATORS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getEnumerator(id) {
    let url = EndPoints.ENUMERATORS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addEnumerator(enumerator) {
    let url = EndPoints.ENUMERATORS_URL

    return apiService
      .create(url, enumerator)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateEnumerator(enumerator) {
    let url = EndPoints.ENUMERATORS_URL + '/' + enumerator.enumeratorID

    return apiService
      .update(url, enumerator)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeEnumerator(enumerator) {
    let url = EndPoints.ENUMERATORS_URL + '/' + enumerator.enumeratorID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getSupervisors(params = {}) {
    return apiService
      .get(EndPoints.SUPERVISORS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getSupervisor(id) {
    let url = EndPoints.SUPERVISORS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addSupervisor(supervisor) {
    let url = EndPoints.SUPERVISORS_URL

    return apiService
      .create(url, supervisor)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateSupervisor(supervisor) {
    let url = EndPoints.SUPERVISORS_URL + '/' + supervisor.supervisorID

    return apiService
      .update(url, supervisor)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeSupervisor(supervisor) {
    let url = EndPoints.SUPERVISORS_URL + '/' + supervisor.supervisorID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getCoordinators(params = {}) {
    return apiService
      .get(EndPoints.COORDINATOR_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateCoordinator(coordinator) {
    let url = EndPoints.COORDINATOR_URL + '/' + coordinator.userID

    return apiService
      .update(url, coordinator)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getOfficers(params = {}) {
    return apiService
      .get(EndPoints.QA_URL + '/officers', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getGnhrOfficers(params = {}) {
    return apiService
      .get(EndPoints.QA_URL + '/officers/gnhr', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getOfficer(id) {
    let url = EndPoints.QA_URL + '/officers/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addOfficer(officer) {
    let url = EndPoints.QA_URL + '/officers'

    return apiService
      .create(url, officer)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateOfficer(officer) {
    let url = EndPoints.QA_URL + '/officers/' + officer.officerID

    return apiService
      .update(url, officer)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeOfficer(officer) {
    let url = EndPoints.QA_URL + '/officers/' + officer.officerID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getGroups(params = {}) {
    return apiService
      .get(EndPoints.GROUPS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addGroup(group) {
    let url = EndPoints.GROUPS_URL

    return apiService
      .create(url, group)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateGroup(group) {
    let url = EndPoints.GROUPS_URL + '/' + group.GroupID

    return apiService
      .update(url, group)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  deleteGroup(group) {
    let url = EndPoints.GROUPS_URL + '/' + group.GroupID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}
