import Vue from 'vue'
import Router from 'vue-router'
import is from 'is_js'
import { store } from './store'
import authService from './api/auth'

const Main = () => import('./containers/Main')
const Login = () => import('./views/auth/Login')
const Reset = () => import('./views/auth/Reset')

const Analytics = () => import('./views/dashboard/Analytics')
const QA = () => import('./views/qa/index')
const Enumerations = () => import('./views/enumerations/index')
const Devices = () => import('./views/devices/index')
const System = () => import('./views/system/index')
const UserProfile = () => import('./views/profile/UserProfile')
const Page404 = () => import('./views/pages/Page404')

Vue.use(Router)

let defaultPath = store.getters.isQAofficer ? '/qa' : '/analytics'
const routes = [
  {
    path: '/',
    redirect: defaultPath,
    name: 'Home',
    component: Main,
    children: [
      {
        path: 'analytics/:tab?',
        name: 'Analytics',
        component: Analytics,
        meta: {
          defaultTab: 'kpis',
          defaultPath: '/analytics',
          tabs: [
            { name: 'KPIs', canShow: !store.getters.isQAofficer, route: 'kpis' },
            { name: 'Performance', canShow: !store.getters.isQAofficer, route: 'performance' },
            { name: 'Geo Tracking', canShow: store.getters.isGnhr || store.getters.isQAadmin, route: 'geo' }
          ]
        }
      },
      {
        path: 'qa/:tab?',
        name: 'Quality Assurance',
        component: QA,
        meta: {
          defaultTab: 'households',
          defaultPath: '/qa',
          tabs: [
            { name: 'Households', canShow: store.getters.isQA, route: 'households' },
            // { name: 'Duplicates', canShow: store.getters.isGnhr && store.getters.isQA, route: 'duplicates' },
            { name: 'Spot Check', canShow: store.getters.isGnhr && store.getters.isAdmin, route: 'spotcheck' },
            { name: 'Officers', canShow: store.getters.isQAadmin || store.getters.isOpsAdmin, route: 'officers' },
            { name: 'GNHR Officers', canShow: store.getters.isGnhr && store.getters.isAdmin, route: 'gofficers' }
          ]
        }
      },
      {
        path: 'enumerations/:tab?',
        name: 'Enumerations',
        component: Enumerations,
        meta: {
          defaultTab: 'enumerators',
          defaultPath: '/enumerations',
          tabs: [
            { name: 'Enumerators', canShow: store.getters.isOpsAdmin, route: 'enumerators' },
            { name: 'Supervisors', canShow: store.getters.isOpsAdmin, route: 'supervisors' },
            { name: 'Coordinators', canShow: store.getters.isOpsAdmin, route: 'coordinators' }
          ]
        }
      },
      {
        path: 'devices/:tab?',
        name: 'Devices',
        component: Devices,
        meta: {
          defaultTab: 'kits',
          defaultPath: '/devices',
          tabs: [
            { name: 'Device Kits', canShow: store.getters.isAdmin, route: 'kits' },
            { name: 'Devices', canShow: store.getters.isAdmin, route: 'list' }
          ]
        }
      },
      {
        path: 'system/:tab?',
        name: 'System',
        component: System,
        meta: {
          defaultTab: 'users',
          defaultPath: '/system',
          tabs: [
            { name: 'Users', canShow: store.getters.isOpsAdmin, route: 'users' },
            { name: 'Groups', canShow: store.getters.isOpsAdmin, route: 'groups' },
            { name: 'Firms', canShow: store.getters.isSuperAdmin, route: 'firms' },
            { name: 'Locations', canShow: store.getters.isSuperAdmin, route: 'locations' },
            { name: 'QA Questions', canShow: store.getters.isSuperAdmin, route: 'questions' }
          ]
        }
      },
      {
        path: 'profile',
        name: 'User profile',
        component: UserProfile
      }
    ]
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'Authentication',
    loggedInOnly: false,
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'reset/:token',
        name: 'Reset',
        props: true,
        component: Reset
      }
    ]
  },
  {
    path: '*',
    component: Page404
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  let openViews = ['Authentication', 'Login', 'Reset', 'Onboard', 'Orientation']
  let canAccess = is.inArray(to.name, openViews) || authService.validAuth(store.getters.isLoggedIn)
  return canAccess ? next() : next('/auth')
})

export default router
