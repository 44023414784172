import apiService from '../../api/locations'
import helpers from '../../mixins/index'

// Mutation types
const SET_REGIONS = 'SET_REGIONS'
const SET_DISTRICTS = 'SET_DISTRICTS'
const SET_COMMUNITIES = 'SET_COMMUNITIES'
const SET_ENUM_AREAS = 'SET_ENUM_AREAS'

const state = {
  enum_areas: [],
  regions: [],
  districts: [],
  communities: []
}

// getters
const getters = {
  getRegions: (state) => {
    return state.regions
  },
  getDistricts: (state) => {
    return state.districts
  },
  getCommunities: (state) => {
    return state.communities
  },
  getEnumAreas: (state) => {
    return state.enum_areas
  },
  getEnumAreasDropdown: (state) => {
    let x = state.enum_areas
      .map(function (e) {
        return { id: e.enum_code, label: e.enum_name, supervisor: e.supervisorID }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All' })
    return x
  },
  getRegionsDropdown: (state) => {
    let distinct = []
    let seen = []
    state.regions.forEach(function (r) {
      if (!seen.includes(r.regionCode)) {
        distinct.push(r)
        seen.push(r.regionCode)
      }
    })

    let x = distinct
      .map(function (e) {
        return { id: e.regionCode, label: e.region }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All' })
    return x
  },
  getDistrictsDropdown: (state) => {
    let eaSum = 0
    let egSum = 0
    let x = state.districts
      .map(function (d) {
        eaSum += d.enumerationActual
        egSum += d.enumerationGoal
        return { id: d.districtCode, label: d.district, region: d.regionCode, region_name: d.region, eg: d.enumerationGoal, ea: d.enumerationActual }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All', region: 0, region_name: 'All', eg: egSum, ea: eaSum })
    return x
  },
  getCommunitiesDropdown: (state) => {
    let eaSum = 0
    let egSum = 0
    let x = state.communities
      .map(function (c) {
        eaSum += c.enumerationActual
        egSum += c.enumerationGoal
        return { id: c.communityCode, label: c.community, district: c.districtCode, district_name: c.district, region_name: c.region, eg: c.enumerationGoal, ea: c.enumerationActual }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All', district: 0, region: 0, district_name: 'All', region_name: 'All', eg: egSum, ea: eaSum })
    return x
  }
}

// mutations
const mutations = {
  [SET_COMMUNITIES](state, payload) {
    state.communities = payload
  },
  [SET_REGIONS](state, payload) {
    state.regions = payload
  },
  [SET_DISTRICTS](state, payload) {
    state.districts = payload
  },
  [SET_ENUM_AREAS](state, payload) {
    state.enum_areas = payload
  }
}

const actions = {
  getLocationData({ dispatch: dispatch }, params) {
    dispatch('getRegions', params)
    dispatch('getDistricts', params)
    dispatch('getCommunities', params)
    dispatch('getEnumAreas', params)
  },
  getCommunities({ commit: commit }, params) {
    return apiService
      .getCommunities(params)
      .then((response) => {
        commit(SET_COMMUNITIES, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  },
  getRegions({ commit: commit }, params) {
    return apiService
      .getRegions(params)
      .then((response) => {
        commit(SET_REGIONS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  },
  getDistricts({ commit: commit }, params) {
    return apiService
      .getDistricts(params)
      .then((response) => {
        commit(SET_DISTRICTS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  },
  getEnumAreas({ commit: commit }, params) {
    return apiService
      .getEnumAreas(params)
      .then((response) => {
        commit(SET_ENUM_AREAS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
