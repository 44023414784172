import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'surveyService',
  components: {
    apiService
  },

  getQAStats(params = {}) {
    return apiService
      .get(EndPoints.HOUSEHOLDS_QA_STATS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getDuplicateStats(params = {}) {
    return apiService
      .get(EndPoints.HOUSEHOLD_MEMBERS_DUPLICATES_URL + '/stats', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getDuplicates(params = {}) {
    return apiService
      .get(EndPoints.HOUSEHOLD_MEMBERS_DUPLICATES_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateDuplicate(payload) {
    let url = EndPoints.HOUSEHOLD_MEMBERS_DUPLICATES_URL + '/update'

    return apiService
      .post(url, payload)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getQaQuestionRefs(params = {}) {
    return apiService
      .get(EndPoints.QA_URL + '/questions/references', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getQaQuestionCodes(params = {}) {
    return apiService
      .get(EndPoints.QA_URL + '/questions/codes', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateQaQuestionCodes(params) {
    let url = EndPoints.QA_URL + '/questions/codes/1'

    return apiService
      .post(url, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getQuestions(params = {}) {
    return apiService
      .get(EndPoints.QUESTIONS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getSpotcheck(params = {}) {
    return apiService
      .get(EndPoints.HOUSEHOLDS_URL + '/spotcheck', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getHouseholds(params = {}) {
    return apiService
      .get(EndPoints.HOUSEHOLDS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getHousehold(id) {
    let url = EndPoints.HOUSEHOLDS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addHousehold(household) {
    let url = EndPoints.HOUSEHOLDS_URL

    return apiService
      .create(url, household)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateHousehold(household) {
    let url = EndPoints.HOUSEHOLDS_URL + '/' + household.hhID

    return apiService
      .update(url, household)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateHouseholdQA(payload) {
    let url = EndPoints.HOUSEHOLDS_URL + '/update/qa'

    return apiService
      .post(url, payload)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeHousehold(household) {
    let url = EndPoints.HOUSEHOLDS_URL + '/' + household.hhID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getHouseholdMembers(params = {}) {
    return apiService
      .get(EndPoints.HOUSEHOLD_MEMBERS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getHouseholdMember(id) {
    let url = EndPoints.HOUSEHOLD_MEMBERS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addHouseholdMember(member) {
    let url = EndPoints.HOUSEHOLD_MEMBERS_URL

    return apiService
      .create(url, member)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateHouseholdMember(member) {
    let url = EndPoints.HOUSEHOLD_MEMBERS_URL + '/' + member.hhID

    return apiService
      .update(url, member)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateHouseholdMemberQA(payload) {
    let url = EndPoints.HOUSEHOLD_MEMBERS_URL + '/update/qa'

    return apiService
      .post(url, payload)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeHouseholdMember(member) {
    let url = EndPoints.HOUSEHOLD_MEMBERS_URL + '/' + member.hhID

    return apiService
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getErrorItems(params = {}) {
    return apiService
      .get(EndPoints.ERRORS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getErrorsForDownload(params = {}) {
    let url = EndPoints.HOUSEHOLDS_URL + '/errors'
    return apiService
      .get(url, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getErrorItem(id) {
    let url = EndPoints.ERRORS_URL + '/' + id
    return apiService
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addErrorItem(errorItem) {
    let url = EndPoints.ERRORS_URL

    return apiService
      .create(url, errorItem)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateErrorItem(errorItem) {
    let url = EndPoints.ERRORS_URL + '/' + errorItem.errorID

    return apiService
      .update(url, errorItem)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeErrorItem(errorItem) {
    let url = EndPoints.ERRORS_URL + '/remove/' + errorItem.errorID

    return apiService
      .create(url, errorItem)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateQuestion(payload) {
    let url = EndPoints.RESPONSES_URL

    return apiService
      .update(url, payload)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}
