import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'AuthService',
  components: {
    apiService
  },

  login(request) {
    return apiService
      .create(EndPoints.AUTH_LOGIN_URL, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  send_reset_email(request) {
    return apiService
      .create(EndPoints.AUTH_RESET_EMAIL_URL, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  reset_password(request) {
    return apiService
      .create(EndPoints.AUTH_RESET_URL, request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  logout() {
    return apiService
      .create(EndPoints.LOGOUT_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  validAuth(loggedIn) {
    return loggedIn
  }
}
