import apiService from '../../api/user'
import helpers from '../../mixins/index'

// Mutation types
const SET_OFFICERS = 'SET_OFFICERS'
const SET_ENUMERATORS = 'SET_ENUMERATORS'
const SET_SUPERVISORS = 'SET_SUPERVISORS'
const SET_COORDINATORS = 'SET_COORDINATORS'

const state = {
  officers: [],
  enumerators: [],
  supervisors: [],
  coordinators: []
}

// getters
const getters = {
  getCoordinators: (state) => state.coordinators,
  getCoordinatorDropdown: (state) => {
    let x = state.coordinators
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All', link: 0 })
    return x
  },
  getEnumerators: (state) => state.enumerators,
  getEnumeratorDropdown: (state) => {
    let x = state.enumerators
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All', link: 0 })
    return x
  },
  getEnumeratorDD: (state) => {
    let x = state.enumerators
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    return x
  },
  getOfficers: (state) => state.officers,
  getOfficerDropdown: (state) => {
    let x = state.officers
      .map(function (e) {
        return { id: e.userID, label: e.fullname, firm_id: e.firm_id }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All', firm_id: 1 })
    return x
  },
  getSupervisors: (state) => state.supervisors,
  getSupervisorDropdown: (state) => {
    let x = state.supervisors
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All', link: 0 })
    return x
  },
  getSupervisorDD: (state) => {
    let x = state.supervisors
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    return x
  },
  getEnumeratorsAndSupervisors: (state) => {
    let enums = state.enumerators
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId, has_device: e.has_device, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    let sups = state.supervisors
      .map(function (e) {
        return { id: e.userID, label: e.fullname, link: e.linkId, has_device: e.has_device, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    return enums.concat(sups)
  },
  getEnumeratorsAndSupervisorsUsingLinkId: (state) => {
    let enums = state.enumerators
      .map(function (e) {
        return { id: e.linkId, label: e.fullname, link: e.linkId, has_device: e.has_device, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    let sups = state.supervisors
      .map(function (e) {
        return { id: e.linkId, label: e.fullname, link: e.linkId, has_device: e.has_device, in_group: e.has_group }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    return enums.concat(sups)
  }
}

// mutations
const mutations = {
  [SET_COORDINATORS](state, payload) {
    state.coordinators = payload
  },
  [SET_ENUMERATORS](state, payload) {
    state.enumerators = payload
  },
  [SET_OFFICERS](state, payload) {
    state.officers = payload
  },
  [SET_SUPERVISORS](state, payload) {
    state.supervisors = payload
  }
}

const actions = {
  getPeople({ dispatch: dispatch }, params) {
    dispatch('getCoordinators', params)
    dispatch('getEnumerators', params)
    dispatch('getOfficers', params)
    dispatch('getSupervisors', params)
  },
  getCoordinators({ commit: commit }, params) {
    return apiService
      .getCoordinators(params)
      .then((response) => {
        commit(SET_COORDINATORS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  },
  getEnumerators({ commit: commit }, params) {
    return apiService
      .getEnumerators(params)
      .then((response) => {
        commit(SET_ENUMERATORS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  },
  getOfficers({ commit: commit }, params) {
    return apiService
      .getOfficers(params)
      .then((response) => {
        commit(SET_OFFICERS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  },
  getSupervisors({ commit: commit }, params) {
    return apiService
      .getSupervisors(params)
      .then((response) => {
        commit(SET_SUPERVISORS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
