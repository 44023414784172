import is from 'is_js'

// Mutation types
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const LOGOUT = 'LOGOUT'
const SET_USER = 'SET_USER'
const SET_ROLES = 'SET_USER_ROLES'

const qaRoles = ['QA Officer', 'QA Supervisor']
const qaAdminRoles = ['QA Supervisor']

const state = {
  isGnhr: false,
  isQA: false,
  isQAofficer: false,
  isQAadmin: false,
  isEnumerator: false,
  isSupervisor: false,
  isCoordinator: false,
  isOperations: false,
  isFirmAdmin: false,
  isAdmin: false,
  isSuperAdmin: false,

  token: '',
  isLoggedIn: false,
  user: null,
  roles: [],
  supervisor_firms: []
}

// getters
const getters = {
  getUser: (state) => state.user,
  getUserRole: (state) => state.user.role,
  getUserFirm: (state) => {
    return is.not.undefined(state.user) ? state.user.firm_id : null
  },
  getRoles: (state) => state.roles,
  getSupervisorFirms: (state) => state.supervisor_firms,
  getRolesDropdown: (state) => {
    let x = state.roles
      .map(function (e) {
        return { id: e.id, label: e.role }
      })
      .sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    x.unshift({ id: 0, label: 'All' })
    return x
  },
  getToken: (state) => state.token,
  isLoggedIn: (state) => state.isLoggedIn,
  isQA: (state) => state.isQA,
  isQAofficer: (state) => state.isQAofficer,
  isQAadmin: (state) => state.isQAadmin,
  isEnumerator: (state) => state.isEnumerator,
  isSupervisor: (state) => state.isSupervisor,
  isCoordinator: (state) => state.isCoordinator,
  isGnhr: (state) => state.isGnhr,
  isOperations: (state) => state.isOperations,
  isOpsAdmin: (state) => state.isOperations || state.isAdmin,
  isAdmin: (state) => state.isAdmin,
  isSuperAdmin: (state) => state.isSuperAdmin
}

function reset(state) {
  state.isQA = false
  state.isQAofficer = false
  state.isQAadmin = false
  state.isEnumerator = false
  state.isSupervisor = false
  state.isCoordinator = false
  state.isGnhr = false
  state.isOperations = false
  state.isFirmAdmin = false
  state.isAdmin = false
  state.isSuperAdmin = false

  state.token = ''
  state.user = null
  state.roles = []
  state.supervisor_firms = []
  state.isLoggedIn = false
}

// mutations
const mutations = {
  [SET_USER](state, user) {
    state.user = user
  },

  [SET_ROLES](state, roles) {
    state.roles = roles.data
  },

  [LOGIN_SUCCESS](state, response) {
    state.token = response.access_token
    state.user = response.user
    state.isGnhr = response.user.is_gnhr
    state.isOperations = response.user.role === 'Operations'
    state.isAdmin = response.user.role === 'Administrator'
    state.isFirmAdmin = state.isAdmin && !state.isGnhr
    state.isSuperAdmin = state.isAdmin && state.isGnhr
    state.isCoordinator = response.user.role.includes('Coordinator') || state.isAdmin || state.isOperations
    state.isSupervisor = response.user.role === 'Supervisor' || state.isCoordinator
    state.isEnumerator = response.user.role === 'Enumerator' || state.isSupervisor
    state.isQAofficer = response.user.role === 'QA Officer'
    state.isQAadmin = is.inArray(response.user.role, qaAdminRoles) || state.isFirmAdmin || state.isOperations
    state.isQA = is.inArray(response.user.role, qaRoles) || state.isFirmAdmin || state.isOperations || state.isAdmin
    state.supervisor_firms = response.user.supervisor_firm_ids
    state.isLoggedIn = true
  },

  [LOGIN_FAILURE](state) {
    reset(state)
  },

  [LOGOUT](state) {
    reset(state)
  }
}

const actions = {
  setUser({ commit: commit }, response) {
    commit(SET_USER, response)
  },

  setRoles({ commit: commit }, response) {
    commit(SET_ROLES, response)
  },

  login({ commit: commit }, response) {
    commit(LOGIN_SUCCESS, response)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })
  },

  failedlogin({ commit: commit }, errors) {
    commit(LOGIN_FAILURE, errors)
  },

  logout(store) {
    store.commit(LOGOUT)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
