import surveyService from '../../api/survey'
import helpers from '../../mixins/index'
import is from 'is_js'

// Mutation types
const SET_QUESTIONS = 'SET_QUESTIONS'

const state = {
  surveys: [],
  questions: [],
  qaStatus: [
    { id: 0, variant: 'secondary', label: 'All', description: 'All status' },
    { id: 1, variant: 'secondary', label: 'Pending', description: 'Just received pending quality assuranceIt can only move to HasErrors or QA_OK' },
    { id: 2, variant: 'danger', label: 'Has Errors', description: 'Gone through Q&A and has errors. Errors should be downloaded by PMT Application.It can only move to ErrorsDownloaded' },
    { id: 3, variant: 'danger', label: 'Errors Downloaded', description: 'Errors have been downloaded by PMT Application.It can only move to Pending' },
    { id: 4, variant: 'info', label: 'Reviewed', description: 'Quality Assurance is completed and the data is OK' },
    { id: 5, variant: 'info', label: 'In Deduplication', description: 'Household has been sent into the deduplication platform' },
    { id: 6, variant: 'info', label: 'In Adjudication', description: 'Duplicates detected in household and has gone for adjudication' },
    { id: 7, variant: 'success', label: 'Completed', description: ' Household has been retrieved from deduplication platform and sent to the registry database' },
    { id: 8, variant: 'danger', label: 'In House Correction', description: 'There are errors that can be fixed in house and does not need to go back to the field.' },
    { id: 9, variant: 'warning', label: 'Resolved', description: 'Errors corrected in house by DBA' },
    { id: 10, variant: 'warning', label: 'Case Management', description: 'Errors sent to case management for correction' },
    { id: 11, variant: 'success', label: 'Ignored', description: 'Reported errors that does not need any correction when checked.' },
    { id: 15, variant: 'danger', label: 'GNHR Rejected', description: 'There are errors detected by GNHR' },
    { id: 16, variant: 'success', label: 'GNHR OK', description: 'Cleared by GNHR' },
    { id: 18, variant: 'secondary', label: 'GNHR Pending', description: 'Pending GNHR review' }
  ],
  qaNewStatus: [
    { id: [1], label: 'Pending' },
    { id: [2, 3, 8, 10], label: 'Firm Rejected' },
    { id: [5], label: 'Duplicates' },
    { id: [6], label: 'Adjudicated' },
    { id: [18], label: 'GNHR Pending' },
    { id: [15], label: 'GNHR Rejected' },
    { id: [4, 11, 9], label: 'Firm OK' },
    { id: [7], label: 'Verified' },
    { id: [16], label: 'GNHR OK' }
  ]
}

// getters
const getters = {
  getQuestions: (state) => {
    let banned = ['uuid']
    return state.questions
      .filter(function (q) {
        return is.not.inArray(q.code, banned) && is.not.null(q.code)
      })
      .sort(function (a, b) {
        return a.code.localeCompare(b.code, undefined, { numeric: true })
      })
  },
  getQAStatus: (state) => state.qaStatus
}

// mutations
const mutations = {
  [SET_QUESTIONS](state, payload) {
    state.questions = payload
  }
}

const actions = {
  getRegistrationData({ dispatch: dispatch }, params) {
    dispatch('getQuestions', params)
  },
  getQuestions({ commit: commit }, params) {
    return surveyService
      .getQuestions(params)
      .then((response) => {
        commit(SET_QUESTIONS, response.data)
      })
      .catch((error) => {
        helpers.handleError(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
