import Vue from 'vue'
import VueResource from 'vue-resource'
import is from 'is_js'
// import { store } from '../store/index'

Vue.use(VueResource)

const API_URL = process.env.VUE_APP_API_URL

const EndPoints = {
  ANALYTICS_BIOMETRICS_URL: API_URL + 'registrations/analytics/biometrics',
  ANALYTICS_DATA_URL: API_URL + 'registrations/analytics/data',
  ANALYTICS_STATS_URL: API_URL + 'registrations/analytics/stats',
  ANALYTICS_GSTATS_URL: API_URL + 'registrations/analytics/gstats',
  ANALYTICS_DC_URL: API_URL + 'registrations/analytics/dayscollecting',
  ANALYTICS_LASTSYNC_URL: API_URL + 'registrations/analytics/lastsync',
  ANALYTICS_NEW_DATA_URL: API_URL + 'registrations/analytics/newdata',
  ANALYTICS_GEO_URL: API_URL + 'registrations/analytics/geo',
  ANALYTICS_GEOALL_URL: API_URL + 'registrations/analytics/geoall',
  ANALYTICS_KPIS_URL: API_URL + 'registrations/analytics/kpis',
  ANALYTICS_PERFORMANCE_URL: API_URL + 'registrations/analytics/performance',
  AUTH_LOGIN_URL: API_URL + 'auth/signin',
  AUTH_LOGOUT_URL: API_URL + 'auth/signout',
  AUTH_RESET_URL: API_URL + 'auth/reset',
  AUTH_RESET_EMAIL_URL: API_URL + 'auth/forgot',
  CASES_URL: API_URL + 'cms/cases',
  DEVICES_URL: API_URL + 'devices',
  DEVICES_KIT_URL: API_URL + 'devices/kits',
  DEVICES_KIT_UPLOAD_URL: API_URL + 'devices/kits/bulk',
  COORDINATOR_URL: API_URL + 'coordinators',
  ENUMERATORS_URL: API_URL + 'enumerators',
  GROUPS_URL: API_URL + 'groups',
  GROUPS_UPLOAD_URL: API_URL + 'groups/bulk',
  FIRM_URL: API_URL + 'firms',
  QA_URL: API_URL + 'qa',
  ERRORS_URL: API_URL + 'qa/errors',
  QA_ERRORS_UPLOAD_URL: API_URL + 'qa/errors/bulk',
  RECRUITS_URL: API_URL + 'recruits',
  SUPERVISORS_URL: API_URL + 'supervisors',
  HOUSEHOLDS_URL: API_URL + 'registrations/households',
  HOUSEHOLDS_QA_STATS_URL: API_URL + 'registrations/households/stats',
  HOUSEHOLD_MEMBERS_URL: API_URL + 'registrations/members',
  HOUSEHOLD_MEMBERS_DUPLICATES_URL: API_URL + 'registrations/members/duplicates',
  RESPONSES_URL: API_URL + 'registrations/responses',
  QUESTIONS_URL: API_URL + 'registrations/questions',
  SURVEYS_URL: API_URL + 'registrations/surveys',
  LOCATIONS_URL: API_URL + 'locations',
  PHOTO_URL: API_URL + 'photos/facial/',
  ROLES_URL: API_URL + 'roles',
  USERS_URL: API_URL + 'users',
  USERS_UPLOAD_URL: API_URL + 'users/bulk'
}

export { EndPoints }
export default {
  name: 'apiService',
  setToken() {
    let vuex = JSON.parse(localStorage.getItem('vuex'))
    if (typeof vuex !== undefined && vuex !== null) {
      let token = vuex.authenticate.token
      if (token !== '') {
        Vue.http.headers.common['Authorization'] = 'Bearer ' + token
      }
    }
  },
  reLogin(resp) {
    let m = is.existy(resp) && is.existy(resp.message) ? resp.message : ''
    if (m === 'Unauthenticated.') {
      window.location.href = '/auth'
      // this.$router.push('/auth')
      // store.dispatch('logout').then(() => {
      // })
    }
  },
  get(url, params = {}) {
    let self = this
    this.setToken()
    return Vue.http
      .get(url, { params: params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        self.reLogin(error.data)
        return Promise.reject(error.data)
      })
  },
  create(url, data) {
    let self = this
    this.setToken()
    return Vue.http
      .post(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        self.reLogin(error.data)
        return Promise.reject(error.data)
      })
  },
  post(url, data) {
    let self = this
    this.setToken()
    return Vue.http
      .post(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        self.reLogin(error.data)
        return Promise.reject(error.data)
      })
  },
  update(url, data) {
    let self = this
    this.setToken()
    return Vue.http
      .put(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        self.reLogin(error.data)
        return Promise.reject(error.data)
      })
  },
  delete(url) {
    let self = this
    this.setToken()
    return Vue.http
      .delete(url)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => {
        self.reLogin(error.data)
        return Promise.reject(error.data)
      })
  }
}
