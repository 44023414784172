import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'FirmService',
  components: {
    apiService
  },

  getFirms (params = {}) {
    return apiService.get(EndPoints.FIRM_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getFirm (id) {
    let url = EndPoints.FIRM_URL + '/' + id
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addFirm (Firm) {
    let url = EndPoints.FIRM_URL

    return apiService.create(url, Firm)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateFirm (Firm) {
    let url = EndPoints.FIRM_URL + '/' + Firm.id

    return apiService.update(url, Firm)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeFirm (Firm) {
    let url = EndPoints.FIRM_URL + '/' + Firm.id

    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}
