import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as actions from './actions'
import * as getters from './getters'
import authenticate from './modules/authenticate'
import firms from './modules/firms'
import locations from './modules/locations'
import people from './modules/people'
import registrations from './modules/registrations'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    authenticate, firms, locations, people, registrations
  },
  plugins: [
    createPersistedState()
  ],
  strict: debug
})