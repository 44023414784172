import Vue from 'vue'
import VueMeta from 'vue-meta'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ElementUI from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue-search-select/dist/VueSearchSelect.css'

import x5GMaps from 'x5-gmaps'

import router from './router'
import mixin from './mixins'
import { store } from './store'
import App from './App.vue'

// require('dotenv').config()

locale.use(lang)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(ElementUI)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.mixin(mixin)

Vue.use(x5GMaps, { key: 'AIzaSyAGgBtGAdzWjgAuVUQeRPYKT-YZJRA6nTI', libraries: ['places,visualization'] })

export const bus = new Vue()

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})
