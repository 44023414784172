import Vue from 'vue'
import VueResource from 'vue-resource'
import apiService, { EndPoints } from './api'

Vue.use(VueResource)

export default {
  name: 'locationService',
  components: {
    apiService
  },

  getLocations (params = {}) {
    return apiService.get(EndPoints.LOCATIONS_URL, params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getEnumAreas (params = {}) {
    return apiService.get(EndPoints.LOCATIONS_URL + '/enumeration_areas', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getRegions (params = {}) {
    return apiService.get(EndPoints.LOCATIONS_URL + '/regions', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addRegion (Region) {
    let url = EndPoints.LOCATIONS_URL + '/regions'

    return apiService.create(url, Region)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateRegion (Region) {
    let url = EndPoints.LOCATIONS_URL + '/regions/' + Region.regionCode

    return apiService.update(url, Region)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeRegion (Region) {
    let url = EndPoints.LOCATIONS_URL + '/regions/' + Region.regionCode

    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getDistricts (params = {}) {
    return apiService.get(EndPoints.LOCATIONS_URL + '/districts', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addDistrict (District) {
    let url = EndPoints.LOCATIONS_URL + '/districts'

    return apiService.create(url, District)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateDistrict (District) {
    let url = EndPoints.LOCATIONS_URL + '/districts/' + District.districtCode

    return apiService.update(url, District)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeDistrict (District) {
    let url = EndPoints.LOCATIONS_URL + '/districts/' + District.districtCode

    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  getCommunities (params = {}) {
    return apiService.get(EndPoints.LOCATIONS_URL + '/communities', params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  addCommunity (Community) {
    let url = EndPoints.LOCATIONS_URL + '/communities'

    return apiService.create(url, Community)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  updateCommunity (Community) {
    let url = EndPoints.LOCATIONS_URL + '/communities/' + Community.communityCode

    return apiService.update(url, Community)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  removeCommunity (Community) {
    let url = EndPoints.LOCATIONS_URL + '/communities/' + Community.communityCode

    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}
